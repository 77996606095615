export const environment = {
  firebase: {
    apiKey: "AIzaSyA3IIiJT6jYxOAcSAALdPscfsl8iQEl05E",
  authDomain: "financial-forecast-v1-ad835.firebaseapp.com",
  projectId: "financial-forecast-v1-ad835",
  storageBucket: "financial-forecast-v1-ad835.appspot.com",
  messagingSenderId: "458092067336",
  appId: "1:458092067336:web:f25eee744265dfa292afbd"
  },
  production: true,
  demo: '', // other possible options are, saas creative and modern
  GOOGLE_MAPS_API_KEY: 'AIzaSyC5pcAkhXSo6-KqV9RtZdUAhdJno7r_8b0'
};
